import { ApiCall } from "@Utils/api";
import { API_ACTIONS, API_ACTION_TYPES } from "@Utils/constants";
import { logError } from "repoV2/utils/common/error/error";
import { getURLSearchParamsObj } from "@Utils/urls";
import { getUserAccessToken } from "@Utils/user";
import { useCallback, useEffect, useState } from "react";
import { getCreatorSubdomainFromUrlHost } from "repoV2/utils/urls&routing/domain";

export const useAccessRestriction = ({
    isPageFilterEnabled,
    listingUuid,
}: {
    isPageFilterEnabled?: boolean;
    listingUuid?: string;
}) => {
    const [
        offeringRestrictedForUserTimeZone,
        setOfferingRestrictedForUserTimeZone,
    ] = useState(false);

    const getOfferingPermission = useCallback(() => {
        const apiInfo =
            API_ACTIONS[API_ACTION_TYPES.GET_OFFERING_TIMEZONE_RESTRICTION];
        const jwtToken = getUserAccessToken();
        // @ts-ignore
        const urlParams = Object.fromEntries([...getURLSearchParamsObj()]);
        const hostName = getCreatorSubdomainFromUrlHost() || "";

        ApiCall({
            url: apiInfo.url({
                listing_uid: listingUuid,
                sub_domain: hostName,
                ...urlParams,
            }),
            method: apiInfo.method,
            headers: {
                "jwt-token": jwtToken,
            },
        })
            .then((apiResponse: any) => {
                if (apiResponse.status !== 200)
                    setOfferingRestrictedForUserTimeZone(true);
            })
            .catch(err => {
                logError({
                    error: err,
                    occuredAt:
                        "oldEventPageTemplate/useOfferingTimezoneRestriction",
                    when: "calling API_ACTION_TYPES.GET_OFFERING_TIMEZONE_RESTRICTION",
                    severity: "medium",
                });
                setOfferingRestrictedForUserTimeZone(true);
            });
    }, [listingUuid]);

    useEffect(() => {
        if (isPageFilterEnabled) getOfferingPermission();
    }, [isPageFilterEnabled]);

    return { offeringAccessDenied: offeringRestrictedForUserTimeZone };
};
