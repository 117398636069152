import { ACTION_TYPES, SELECTORS, createAction } from "@Utils";
import { useDispatch, useSelector } from "react-redux";

interface IUseSetCurrentPlan {
    planUuid: string | null;
}

/**
 * @description This hook returns the currentPlanUuid (plan id that is currently selected/active)
 * and a setter to set this value.
 */
export const useSetCurrentPlan = () => {
    const dispatch = useDispatch();
    const { currentPlanUuid } = useSelector(SELECTORS.plansData);
    const setCurrentPlanId = (planUuid: IUseSetCurrentPlan["planUuid"]) => {
        dispatch(
            createAction(ACTION_TYPES.HOST.SET_CURRENT_PLAN_ID, {
                planUuid,
            })
        );
    };
    return {
        currentPlanUuid,
        setCurrentPlanId,
    };
};
