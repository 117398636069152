import { useState } from "react";
import { IHost } from "@Interfaces";
import { useContentData } from "@Modules/contentList/ContentContext/ContentContext";
import { ACTION_TYPES, createAction } from "@Utils/constants/actions";
import { API_ACTIONS, API_ACTION_TYPES } from "@Utils/constants/api";
import { ApiCall } from "@Utils/api";
import { getUserAccessToken } from "@Utils/user";
import { pluralise } from "@Utils/common";
import { parseDateTime } from "@Utils/parseDateTime";
import { isFuture } from "date-fns";
import { useDispatch } from "react-redux";
import { logError } from "repoV2/utils/common/error/error";
import { COURSE_UNLOCKING_TYPES, dateFormats } from "./data";

export const useRefetchContentDetails = () => {
    const dispatch = useDispatch();
    const { selectedListingId } = useContentData();
    const [fetching, setFetching] = useState(false);

    const refetchCertificateDetails = (props?: {
        successCallback: () => void;
    }) => {
        const { successCallback } = props || {};
        dispatch(
            createAction(ACTION_TYPES.UTILS.API_CALL, {
                apiActionType: API_ACTION_TYPES.GET_COURSE_CERTIFICATE_DETAILS,
                urlArgs: {
                    listingUuid: selectedListingId,
                },
                headers: {
                    "jwt-token": getUserAccessToken(),
                },
                successCallback,
            })
        );
    };

    const refetchContentDetails = () => {
        setFetching(true);
        dispatch(
            createAction(ACTION_TYPES.UTILS.API_CALL, {
                apiActionType: API_ACTION_TYPES.FETCH_CONTENT_DETAILS,
                urlArgs: {
                    listingId: selectedListingId,
                },
                headers: {
                    "jwt-token": getUserAccessToken(),
                },
                finallyCallback: () => {
                    setFetching(false);
                },
            })
        );
    };

    return {
        fetching,
        refetchContentDetails,
        refetchCertificateDetails,
    };
};

/**
 * Updates the state of recorded content in a course.
 *
 * @param {Object} params - Parameters for updating the content state.
 * @param {string} params.uuid - The unique identifier of the recorded content.
 * @param {boolean} [params.isCompleted] - Indicates whether the content is completed.
 * @param {number} [params.watchedDuration] - The duration of the video watched by the user.
 * @param {number} [params.totalDuration] - The total duration of the video.
 * @param {Function} [params.successCallBack] - Callback function to be executed on success.
 * @returns {Promise<any>} - A Promise that resolves with the updated data.
 * @throws {Error} - If the API response status is not 200, an error is thrown with the message from the API.
 */
export const markRecordedContentState = ({
    uuid: recorded_content_uid,
    isCompleted: is_completed,
    watchedDuration: watched_duration,
    totalDuration: total_duration,
    successCallBack,
    finallyCallBack,
}: {
    uuid: string;
    isCompleted?: boolean;
    watchedDuration?: number;
    totalDuration?: number;
    successCallBack?: Function;
    finallyCallBack?: Function;
}) => {
    const apiInfo = API_ACTIONS[API_ACTION_TYPES.UPDATE_RECORDED_CONTENT_MARK];
    const jwtToken = getUserAccessToken();

    const payload: { watched_duration?: number; total_duration?: number } = {};
    if (watched_duration) payload.watched_duration = watched_duration;
    if (total_duration) payload.total_duration = total_duration;

    return ApiCall({
        url: apiInfo.url(),
        method: apiInfo.method,
        payload: {
            recorded_content_uid,
            is_completed,
            ...payload,
        },
        configOptions: { shouldRelogin: true },
        headers: { "jwt-token": jwtToken },
    })
        .then((apiResponse: any) => {
            const { status, data, message } = apiResponse?.data || {};
            if (status !== 200) throw new Error(message);
            if (successCallBack) successCallBack();
            return data;
        })
        .catch(error =>
            logError({
                error,
                severity: "critical",
                when: "updateRecordedContentResumeState",
            })
        )
        .finally(() => finallyCallBack?.());
};

export const markLessonComplete = ({
    subCategoryUuid,
    successCallBack,
}: {
    subCategoryUuid: string;
    successCallBack?: Function;
}) => {
    const apiInfo =
        API_ACTIONS[API_ACTION_TYPES.MARK_RECORDED_CONTENT_LESSON_COMPLETE];
    const jwtToken = getUserAccessToken();

    return ApiCall({
        url: apiInfo.url(),
        method: apiInfo.method,
        payload: { sub_category_uuid: subCategoryUuid },
        configOptions: { shouldRelogin: true },
        headers: { "jwt-token": jwtToken },
    })
        .then((apiResponse: any) => {
            const { status, data, message } = apiResponse?.data || {};
            if (status !== 200) throw new Error(message);
            if (successCallBack) successCallBack();
            return data;
        })
        .catch(error =>
            logError({
                error,
                severity: "critical",
                when: "markLessonComplete",
            })
        );
};

/**
 * TODO: describe function description and its purpose
 */
export const getFlatCourseLessons = ({
    courseData,
    hostData,
}: {
    courseData: IHost.ICourseDataItem;
    hostData: IHost.IHostData;
}) => {
    let lessonCount = 0;
    if (!courseData?.categories) return {};

    const {
        unlocking_type: unlockingType,
        has_lesson_unlocking: hasLessonUnlocking,
    } = courseData;
    const isPostPreviousCompletion =
        unlockingType === COURSE_UNLOCKING_TYPES.POST_PREVIOUS_COMPLETION;
    const isUnlockedImmediately =
        unlockingType === COURSE_UNLOCKING_TYPES.IMMEDIATELY;

    const wordUsedForCourseLesson = getWordUsedForCourseLesson(hostData);
    const wordUsedForCourseSection = getWordUsedForCourseSection(hostData);

    return Object.assign(
        {},
        ...courseData.categories.map(
            (category, sectionIndex, sectionsArray) => {
                const isCurrentSectionLocked = isSectionLocked({
                    courseData,
                    section: category,
                    previousSection:
                        sectionIndex > 0
                            ? sectionsArray[sectionIndex - 1]
                            : undefined,
                });
                return Object.assign(
                    {},
                    ...category.sub_categories.map(
                        (
                            lesson: IHost.ISubCategory,
                            lessonIndex,
                            lessonsArray
                        ) => {
                            const isLockedBasedOnInstallment =
                                category?.installment_details
                                    ? !category.installment_details.is_unlocked
                                    : false;
                            const isLocked = isUnlockedImmediately
                                ? false
                                : isLockedBasedOnInstallment ||
                                  isCurrentSectionLocked ||
                                  isLessonLocked({
                                      subCategoryItem: lesson,
                                      previousSubcategory:
                                          lessonIndex > 0
                                              ? lessonsArray[lessonIndex - 1]
                                              : undefined,
                                      previousCategoryItem:
                                          sectionIndex > 0
                                              ? sectionsArray[sectionIndex - 1]
                                              : undefined,
                                      courseData,
                                  });

                            let lockedMessage = "";
                            if (isPostPreviousCompletion) {
                                lockedMessage = `Complete ${pluralise(
                                    wordUsedForCourseLesson,
                                    lessonsArray.length
                                )} from previous ${pluralise(
                                    wordUsedForCourseSection,
                                    courseData.categories.length
                                )} to unlock this ${
                                    hasLessonUnlocking
                                        ? wordUsedForCourseLesson
                                        : wordUsedForCourseSection
                                }.`;
                            } else if (lesson?.unlock_time) {
                                lockedMessage = parseDateTime(
                                    lesson?.unlock_time,
                                    dateFormats.lockedLesson
                                );
                            } else if (isCurrentSectionLocked) {
                                lockedMessage = parseDateTime(
                                    category?.unlock_time,
                                    dateFormats.lockedLesson
                                );
                            } else if (
                                category?.installment_details
                                    ?.scheduled_datetime
                            ) {
                                lockedMessage = parseDateTime(
                                    category.installment_details
                                        .scheduled_datetime,
                                    dateFormats.lockedLesson
                                );
                            }

                            lessonCount += 1;

                            return {
                                [lesson.uuid]: {
                                    ...lesson,
                                    lessonNumber: lessonCount,
                                    isLocked,
                                    lockedMessage,
                                    installment_details:
                                        category.installment_details,
                                    sectionUuid: category.uuid,
                                },
                            };
                        }
                    )
                );
            }
        )
    );
};

export const isLessonLocked = ({
    subCategoryItem: lesson,
    previousCategoryItem: previousSection,
    previousSubcategory: previousLesson,
    courseData,
}: {
    subCategoryItem: IHost.ISubCategory;
    previousCategoryItem?: IHost.ICourseCategory;
    previousSubcategory?: IHost.ISubCategory;
    courseData: IHost.ICourseDataItem;
}): boolean => {
    const {
        unlocking_type: unlockingType,
        has_lesson_unlocking: hasLessonUnlocking,
    } = courseData;

    const isPostPreviousCompletion =
        unlockingType === COURSE_UNLOCKING_TYPES.POST_PREVIOUS_COMPLETION;

    if (isPostPreviousCompletion) {
        if (hasLessonUnlocking && previousLesson)
            return previousLesson ? !previousLesson.is_completed : false;
        return previousSection ? !previousSection.is_completed : false;
    }

    if (lesson?.unlock_time)
        return isFuture(parseDateTime(lesson.unlock_time).valueOf() || 0);

    return false;
};

export const isSectionLocked = ({
    section,
    previousSection,
    courseData,
}: {
    section: IHost.ICourseCategory;
    previousSection?: IHost.ICourseCategory;
    courseData: IHost.ICourseDataItem;
}): boolean => {
    const { unlocking_type: unlockingType } = courseData;

    const isPostPreviousCompletion =
        unlockingType === COURSE_UNLOCKING_TYPES.POST_PREVIOUS_COMPLETION;

    if (isPostPreviousCompletion)
        return previousSection ? !previousSection.is_completed : false;

    if (section?.unlock_time)
        return isFuture(parseDateTime(section.unlock_time).valueOf() || 0);

    return false;
};

export const getPDFJsViewEmbedUrl = ({ fileUrl }: { fileUrl: string }) =>
    `/pdfjs-3.7.107-dist/web/viewer.html?file=${fileUrl}`;

/**
 * @returns text in lowercase to replace "course" word for recorded content used across host site
 */
export const getWordUsedForCourse = (
    hostData?: IHost.IHostData,
    customDefault?: string
): string =>
    (
        hostData?.custom_word_for_course ||
        customDefault ||
        "course"
    ).toLowerCase();

/**
 * @returns text in lowercase to replace "mark" word for score in recorded content used across host site
 */
export const getWordUsedForCourseMark = (hostData?: IHost.IHostData): string =>
    (hostData?.custom_word_for_course_mark || "mark").toLowerCase();

/**
 * @returns text in lowercase to replace "section" word in recorded content used across host site
 */
export const getWordUsedForCourseSection = (
    hostData?: IHost.IHostData
): string =>
    (hostData?.custom_word_for_course_section || "section").toLowerCase();

/**
 * @returns text in lowercase to replace "lesson" word in recorded content used across host site
 */
export const getWordUsedForCourseLesson = (
    hostData?: IHost.IHostData
): string =>
    (hostData?.custom_word_for_course_lesson || "lesson").toLowerCase();

/**
 * @returns text in lowercase to replace "question" word in recorded content used across host site
 */
export function getWordUsedForCourseQuestion(
    hostData?: IHost.IHostData
): string {
    return (
        hostData?.custom_word_for_course_question || "question"
    ).toLowerCase();
}

/**
 * @returns text in lowercase to replace "exam" word in recorded content used across host site
 */
export function getWordUsedForCourseExam(hostData?: IHost.IHostData) {
    return (hostData?.custom_word_for_course_exam || "exam").toLowerCase();
}
