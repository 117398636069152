import React from "react";
import { ACTION_TYPES, createAction } from "@Redux";
import { API_ACTION_TYPES, useEventDataOfSelectedEvent } from "@Utils";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { logError } from "repoV2/utils/common/error/error";

export const fetchMiscellaneousData = ({
    listingUuid,
    dispatch,
}: {
    listingUuid: string;
    dispatch: Dispatch<any>;
}) => {
    const errorCallback = (e: any) => {
        logError({
            error: e,
            occuredAt: "useFetchMiscellaneousData",
            when: "calling API_ACTION_TYPES.FETCH_LISTING_MISCELLANEOUS_DATA",
            severity: "medium",
        });
    };

    dispatch(
        createAction(ACTION_TYPES.UTILS.API_CALL, {
            apiActionType: API_ACTION_TYPES.FETCH_LISTING_MISCELLANEOUS_DATA,
            urlArgs: { listingUuid },
            errorCallback,
        })
    );
};

export const useFetchMiscellaneousData = ({
    listingUuid,
}: {
    listingUuid: string;
}) => {
    const dispatch = useDispatch();
    const { selectedEventUuid } = useEventDataOfSelectedEvent();
    // TODO: stop the API calls on changing the eventID, by storing the data according to eventID
    React.useEffect(() => {
        fetchMiscellaneousData({
            listingUuid,
            dispatch,
        });
    }, [selectedEventUuid]);

    return null;
};
