import { IHost } from "@Interfaces";
import { createContext, useContext } from "react";
import {
    getWordUsedForCourseExam,
    getWordUsedForCourseQuestion,
} from "@Utils/content/utils";
import { IContentContext } from "./IContentContext";

/**
 * @deprecated
 * No new states are to be added or used since it contains heavy hostData.
 * Create a new context state for futher usages.
 */
export const ContentContext = createContext<IContentContext.IProps>({
    wordUsedForCourseExam: getWordUsedForCourseExam(),
    wordUsedForCourseQuestion: getWordUsedForCourseQuestion(),
    hostData: {} as IHost.IHostData,
    flatItemData: {},
    loadingBookingDetails: false,
    isNPSEnabled: false,
    isDesktop: false,
    courseData: {
        automatic_lesson_completion_enabled: false,
    } as IHost.ICourseDataItem, // for future devs: please add default values for properties and will eventually remove 'Type assertion' from here
    lessons: [],
});

export const useContentData = () => useContext(ContentContext);
