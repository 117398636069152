import { IHost } from "@Interfaces";
import { ITemplate } from "@Templates/ITemplate";
import { ABOUT_SECTION_SOCIAL_ICONS } from "./constants";

export const getSortedSocialLinks = (
    socialInfo: IHost.IHostData["social_info"]
) => {
    let socialLinks: ITemplate.ISocialIconsProps["socialLinks"] = {};
    Object.keys(ABOUT_SECTION_SOCIAL_ICONS)
        .filter(socialKey => !!socialInfo[socialKey])
        .forEach(socialKey => {
            let link = socialInfo[socialKey];
            // to let browser know the url is an external url
            link = link.includes("http") ? link : `//${link}`;
            let followerCount = 0;
            if (
                socialInfo?.follower_count &&
                socialInfo?.follower_count[socialKey]
            ) {
                followerCount = socialInfo?.follower_count[socialKey];
            }

            const socialInfoObj = {
                socialKey,
                link,
                followerCount,
            };
            socialLinks = { ...socialLinks, [socialKey]: socialInfoObj };
        });

    const sortedSocialList = Object.values(socialLinks).sort(
        (a: any, b: any) => {
            return b.followerCount - a.followerCount;
        }
    );

    const sortedSocialLinksObj: any = {};
    sortedSocialList.forEach(item => {
        sortedSocialLinksObj[item.socialKey] = item;
    });

    return sortedSocialLinksObj;
};
